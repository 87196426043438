/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { ThemeProvider } from "styled-components"
import Theme from "@styles/theme"
import GlobalStyles from "@styles/global/global"
import { withAssetPrefix } from "gatsby"
import { LocationContext } from "@hooks/use-location"
import "./src/fonts/typography.css"

// You can delete this file if you're not using it
export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={Theme}>
        <LocationContext.Provider value={props.location}>
          {element}
        </LocationContext.Provider>
      </ThemeProvider>
    </>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const manifestEl = document.head.querySelector(`link[rel="manifest"]`)
  if (manifestEl) {
    manifestEl.setAttribute(`href`, withAssetPrefix(`manifest.webmanifest`))
  }
}

// function initGTMOnEvent(event) {
//   initGTM();
//   event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
// }

// function initGTM() {
//   if (window.gtmDidInit) {
//     return false;
//   }

//   window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

//   var script = document.createElement('script');

//   script.type = 'text/javascript';
//   script.async = true;
//   script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-PNCCDQ`;

//   script.onload = function() {
//     // eslint-disable-next-line no-undef
//     dataLayer.push({
//       // ensure PageViews are always tracked
//       event: 'gtm.js',
//       'gtm.start': new Date().getTime(),
//       'gtm.uniqueEventId': 0,
//     });
//   };

//   document.head.appendChild(script);
// }

export function onClientEntry() {
  // document.onreadystatechange = function() {
  //   if (document.readyState !== 'loading') setTimeout(initGTM, 1000);
  // };

  // document.addEventListener('scroll', initGTMOnEvent);
  // document.addEventListener('mousemove', initGTMOnEvent);
  // document.addEventListener('touchstart', initGTMOnEvent);
  
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })

  if (params.refcode) {
    document.cookie = `refcode=${params.refcode}; expires=${new Date(
      new Date().getTime() + 1000 * 60 * 60 * 24 * 90
    ).toUTCString()}; domain=klipfolio.com; path=/`
  }
}
