import { createGlobalStyle } from "styled-components"
export default createGlobalStyle`
  html, body #___gatsby {
    height: -webkit-fill-available;
  }
  body {
    height: -webkit-fill-available;
    position: relative;
  }
  #gatsby-focus-wrapper {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    display: flex;
    flex-flow: column;
  }
  .blog-table {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    border-collapse: separate !important;
    border-spacing: 0;
    overflow: hidden;
    
    th, td {
      padding: 15px;
    }
    tr td:not(:first-child) {
      text-align: center;
      width: 120px;
    }
    tr:first-child {
      background: #B8BFD6!important;
    }
    tr:nth-child(odd) {
       background: #DEE2ED;
    }
    th {
      text-align: center;
    }
  }
`
